const summaries = {
  // Please NOTE: for the CAT questionnaire, a lower score is better.
  // This is different in comparison to other questionnaires where higher score is better.
  low: {
    min: 0,
    max: 10,
    group: 'low',
    title: 'b2b_quiz_cat_low_title',
    recommendations: [
      {
        title: 'b2b_quiz_cat_profile_title',
        recommendation: 'b2b_quiz_cat_low_profile',
      },
      {
        title: 'b2b_quiz_cat_training_title',
        recommendation: 'b2b_quiz_cat_low_training',
      },
    ],
  },
  medium: {
    min: 11,
    max: 20,
    group: 'medium',
    title: 'b2b_quiz_cat_medium_title',
    recommendations: [
      {
        title: 'b2b_quiz_cat_profile_title',
        recommendation: 'b2b_quiz_cat_medium_profile',
      },
      {
        title: 'b2b_quiz_cat_training_title',
        recommendation: 'b2b_quiz_cat_medium_training',
      },
    ],
  },
  high: {
    min: 21,
    max: 30,
    group: 'high',
    title: 'b2b_quiz_cat_high_title',
    recommendations: [
      {
        title: 'b2b_quiz_cat_profile_title',
        recommendation: 'b2b_quiz_cat_high_profile',
      },
      {
        title: 'b2b_quiz_cat_training_title',
        recommendation: 'b2b_quiz_cat_high_training',
      },
    ],
  },
  veryhigh: {
    min: 31,
    max: 40,
    group: 'very-high',
    title: 'b2b_quiz_cat_very_high_title',
    recommendations: [
      {
        title: 'b2b_quiz_cat_profile_title',
        recommendation: 'b2b_quiz_cat_very_high_profile',
      },
      {
        title: 'b2b_quiz_cat_training_title',
        recommendation: 'b2b_quiz_cat_very_high_training',
      },
    ],
  },
}

export default {
  state: {
    answers: {
      cough_frequency: null,
      phlegm_on_chest: null,
      chest_tightness: null,
      out_of_breath_when_walking: null,
      limited_activities_at_home: null,
      not_confident_leaving_home: null,
      bad_sleep: null,
      low_energy_level: null,
    },
    score: 0, // Int [0 to 40]
  },
  getters: {
    catQuestions: (state) =>
      Object.entries(state.answers).map(([key, value]) => ({ key, value })),
    catQuestionsScore: (state) => state.score,
    catSummary: (state) => {
      const { score } = state
      const key =
        score > 30
          ? 'veryhigh'
          : score > 20
            ? 'high'
            : score > 10
              ? 'medium'
              : 'low'
      return {
        score,
        summary: summaries[key],
      }
    },
    catAnswers: (state) =>
      Object.entries(state.answers).reduce((data, [key, value]) => {
        data[key.replace(/_/g, '-')] = value
        return data
      }, {}),
  },
}
