<template>
  <CATListLayout @next-btn-click="next">
    <template #header>
      <QuizNavHeader
        :stage="currentQuestionnaire"
        :stage-count="questionnaireCount"
        :stage-progress="stageProgress"
        :title="$t('b2b_quiz_cat_nav_title')"
        @btn-back-clicked="back"
      />
    </template>

    <template #default>
      <h3
        class="form-header"
        v-html="$t('b2b_quiz_cat_questions_description')"
      />
      <div
        v-for="catQuestion in catQuestions"
        :key="catQuestion.key"
        class="form-container"
      >
        <div class="table">
          <div class="table__left">
            <div
              v-html="$t(LANG_PREFIX + catQuestion.key + '_min_description')"
            />
          </div>
          <div class="table__center">
            <CATListItem
              :update="updateQuestion"
              :state="!errors[catQuestion.key]"
              :question-key="catQuestion.key"
              :value="catQuestion.value"
            />
          </div>
          <div class="table__right">
            <div
              v-html="$t(LANG_PREFIX + catQuestion.key + '_max_description')"
            />
          </div>
        </div>
      </div>
    </template>
  </CATListLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'
import CATListItem from '@shared/components/Quiz/CATListItem.vue'
import CATListLayout from '@shared/modules/assessment/views/QuestionnaireCAT/CATList/CATListLayout.vue'
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'QuestionnaireCATListDesktop',
  components: {
    CATListItem,
    CATListLayout,
    QuizNavHeader,
  },
  emits: ['back', 'next'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      LANG_PREFIX: 'b2b_quiz_cat_',
      errors: {},
      formIsDisabled: false,
    }
  },
  computed: {
    ...mapGetters('assessment', [
      'currentQuestionnaire',
      'questionnaireCount',
      'catQuestions',
    ]),
    stageProgress() {
      const answeredQuestions = this.catQuestions.filter(
        (question) => question.value != null,
      )
      return (answeredQuestions.length / this.catQuestions.length) * 85 + 10
    },
  },
  methods: {
    ...mapActions({
      updateCATField: 'assessment/updateCATField',
      updateCATScore: 'assessment/updateCATScore',
    }),
    updateQuestion(event) {
      this.updateCATField(event)
      this.updateCATScore()
      delete this.errors[event.name]
    },
    back() {
      this.$emit('back')
    },
    validate() {
      const errors = {}

      this.catQuestions.forEach((catQuestion) => {
        if (catQuestion.value == null) {
          errors[catQuestion.key] = this.t('generic_field_required_unlabeled')
        }
      })

      return {
        isValid: !Object.keys(errors).length,
        errors,
      }
    },
    next() {
      if (this.formIsDisabled) {
        return
      }

      const { errors, isValid } = this.validate()
      this.errors = errors

      if (isValid) {
        this.formIsDisabled = true

        this.$emit('next')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-header {
  text-align: center;
  margin: 16px 0;
  word-wrap: break-word;
  overflow: hidden;

  @include kaia-typography-h6($font-weight-bold);
}

.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-5;
  padding-bottom: 20px;
  margin-bottom: 20px;
  line-height: 145%;
}
.form-container:last-child {
  border-bottom: 0;
}

.table {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'left right'
    'center center';

  @include media-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'left center right';
    align-items: center;
  }

  &__left {
    grid-area: left;
    font-size: 80%;
    word-wrap: break-word;
    overflow: hidden;
  }

  &__center {
    grid-area: center;
    display: flex;
  }

  &__right {
    grid-area: right;
    text-align: right;
    font-size: 80%;
    word-wrap: break-word;
    overflow: hidden;
  }
}
</style>
