<template>
  <div class="quiz-content__inner self-test commitment-intro">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_commitment_intro_header')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b_self_test_commitment_intro_description')"
        />
      </div>
      <div class="commitment-intro__background" />
      <div class="interactive-button__layout">
        <BaseButton
          :text="$t('b2b_self_test_commitment_intro_cta')"
          data-qa="submit"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isFromApp } from '@shared/helpers/isFromApp'
import Tracker from '@shared/Tracker'
import BaseButton from '@shared/components/BaseButton.vue'

export default {
  name: 'SelfTestCommitmentIntro',
  components: {
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('assessment', ['selfTestAnswers']),
  },
  methods: {
    ...mapActions('assessment', ['submitSelfTest']),
    async next() {
      try {
        await this.submitSelfTest({ answers: this.selfTestAnswers })

        Tracker.trackKaiaEvent(
          {
            event_name: 'cb.onbrdg.submit_initial_assessment',
            app_area: 'onboarding',
            action: 'submit',
            object_type: 'initial_assessment',
            source: 'client_browser',
            screen_name: 'onb_finish_congratulations',
          },
          {
            custom_payload: {
              ...this.selfTestAnswers,
              from_app: isFromApp(),
            },
          },
        )
      } catch {
        /* empty */
      }

      this.handleNextEvent()
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';
.commitment-intro {
  background-color: $light-blue;
  &__background {
    height: 200px;
    margin-top: 32px;
    margin-bottom: -1px;
    background: url('@shared/assets/img/commitment-intro-background_people.svg')
      center bottom no-repeat;
    background-size: contain;
  }
  .self-test__layout {
    background: url('@shared/assets/img/commitment-intro-background_fireworks.svg')
      center 86px no-repeat;
    background-size: 75%;
  }
  .self-test__header {
    @include kaia-typography-h5($font-weight-bold);
  }
  .self-test__description {
    color: $midnight;
  }
  .interactive-button__layout {
    margin: 0 auto;
  }
}
</style>
