<template>
  <div class="confirmation-box">
    <div
      :class="[
        'form-checkbox',
        { 'form-checkbox__invalid': shouldAlertCheckbox },
      ]"
    >
      <div class="form-checkbox__layout">
        <custom-checkbox
          :name="checkboxId"
          :checked="checked"
          value="confirmed"
          :data-q-a="'confirmation-' + checkboxId"
          @change="handleChangeEvent"
        />
      </div>
      <label
        class="form-checkbox__label"
        :for="checkboxId"
        v-html="labelTitle"
      />
    </div>

    <button
      type="button"
      :class="['btn btn-secondary', { 'btn-disabled': !ctaEnabled }]"
      :disabled="!ctaEnabled"
      data-qa="submit"
      @click="handleClickEvent"
      v-html="ctaTitle"
    />
  </div>
</template>

<script>
import CustomCheckbox from '@shared/components/CustomCheckbox.vue'

export default {
  name: 'ConfirmationBox',
  components: {
    CustomCheckbox,
  },
  inheritAttrs: false,
  props: {
    labelTitle: {
      type: String,
      default: '',
    },
    ctaTitle: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    ctaEnabled: {
      type: Boolean,
      default: false,
    },
    shouldAlertCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit', 'change'],
  data() {
    return {
      checkboxId: `id_${Date.now()}`,
    }
  },
  methods: {
    handleClickEvent(event) {
      if (this.checked) {
        this.$emit('submit', event)
      }
    },
    handleChangeEvent(event) {
      this.$emit('change', event)
    },
  },
}
</script>

<style lang="scss">
@import '@shared/components/css/FormCheckbox.scss';
@import './css/ConfirmationBox.scss';
</style>
