<template>
  <div
    :class="cssClassesComputed"
    @click="handleClickEvent"
  >
    <input
      type="checkbox"
      class="dropdown-arrow-control__input"
      :disabled="disabled"
      @focus="handleFocusEvent"
      @blur="handleBlurEvent"
    />
  </div>
</template>

<script>
const ENTER = 13

export default {
  name: 'DropdownArrowControl',
  props: {
    cssClasses: {
      type: Array,
      default: () => [],
    },
    defaultState: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      state: this.defaultState === true,
      isFocused: false,
    }
  },
  computed: {
    cssClassesComputed() {
      return [
        'dropdown-arrow-control',
        this.state ? 'dropdown-arrow-control--on' : '',
        this.isFocused ? 'dropdown-arrow-control--focused' : '',
        ...this.cssClasses,
      ]
    },
  },
  methods: {
    handleClickEvent() {
      if (this.disabled) {
        return
      }

      this.state = !this.state
      this.$emit('click', this.state)
    },
    handleFocusEvent() {
      this.isFocused = true
      this.$el.addEventListener('keydown', this.handleKeyDownEvent)
    },
    handleBlurEvent() {
      this.isFocused = false
      this.$el.removeEventListener('keydown', this.handleKeyDownEvent)
    },
    handleKeyDownEvent(event) {
      if (event.keyCode === ENTER) {
        this.$el.click()
      }
    },
  },
}
</script>

<style lang="scss">
.dropdown-arrow-control {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s linear;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    border: 12px solid transparent;
    border-top-color: $gray-15;
    border-radius: 6px;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    border: 12px solid transparent;
    border-top-color: $white;
    border-radius: 6px;
    z-index: 2;
  }

  &--on {
    transform: rotate(180deg);
  }

  &--focused {
    &::before {
      border-top-color: $blue;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
}
</style>
