<template>
  <div :class="['quiz-meter', { 'is-invalid': !state }]">
    <div class="quiz-meter__inner">
      <a
        v-for="option in [0, 1, 2, 3, 4, 5]"
        :key="option"
        :class="[
          'quiz-meter__btn',
          { selected: option === value },
          { first: option === 0 },
          { last: option === 5 },
        ]"
        @click="update({ name: questionKey, value: option })"
      >
        {{ option }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CATListItem',
  props: {
    value: {
      type: Number,
      default: null,
    },
    state: {
      type: Boolean,
      default: true,
    },
    update: {
      type: Function,
      required: true,
    },
    questionKey: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.quiz-meter {
  margin: 0 auto;

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    margin-top: 15px;
  }

  &.is-invalid &__inner {
    box-shadow: 0 0 0 2px $danger;
    border-radius: 8px;
  }

  &__btn {
    min-width: 44px;
    width: 10%;
    height: 44px;
    display: block;
    text-decoration: none;
    text-align: center;
    background-color: $gray-base;
    background-position: center;
    color: $midnight;
    line-height: 44px;
    border-radius: 0;
    cursor: pointer;

    &.first {
      border-radius: 8px 0 0 8px;
    }

    &.last {
      border-radius: 0 8px 8px 0;
    }

    &.btn {
      display: none;
    }

    &.selected {
      background: #b5d780;
      border-radius: 4px;
      position: relative;
      -webkit-box-shadow: 0 0 0 5px #b5d780;
      -moz-box-shadow: 0 0 0 5px #b5d780;
      box-shadow: 0 0 0 5px #b5d780;
      transition: all 0.25s ease-in-out;
      font-weight: 600;
    }
  }
}
</style>
