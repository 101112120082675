<template>
  <svg
    viewBox="0 0 141 423"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      :class="bodyParts"
      transform="translate(10 10)"
      @click="handleClickEvent"
    >
      <path
        d="M46.6345 371.042H31.8259L33.1816 378.914C33.8073 382.564 33.5466 386.526 32.1387 390.071C30.5223 394.137 27.498 396.535 23.9001 398.725C22.753 399.455 22.4401 401.019 23.2744 402.062C23.6915 402.635 24.3694 402.948 25.0994 402.948H43.871C45.3831 402.948 46.5303 401.644 46.426 400.185C46.0088 395.493 44.966 390.749 44.9138 386.004C44.8095 381 45.9045 375.995 46.6345 371.042Z"
        fill="#FCC3B4"
      />
      <path
        d="M31.8259 371.042H46.6345C46.4781 372.241 46.2695 373.492 46.061 374.692H32.4516C32.1387 372.815 31.8259 371.042 31.8259 371.042Z"
        fill="#F29788"
      />
      <path
        d="M75.9911 371.042H90.7998L89.4441 378.914C88.8184 382.564 89.0791 386.526 90.4869 390.071C92.1034 394.137 95.1277 396.535 98.7256 398.725C99.8727 399.455 100.186 401.019 99.3513 402.062C98.9341 402.635 98.2563 402.948 97.5263 402.948H78.7547C77.2426 402.948 76.0954 401.644 76.1997 400.185C76.6169 395.493 77.6597 390.749 77.7119 386.004C77.8162 381 76.7212 375.995 75.9911 371.042Z"
        fill="#FCC3B4"
      />
      <path
        d="M90.7998 371.042H75.9911C76.1476 372.241 76.3562 373.492 76.5647 374.692H90.122C90.487 372.815 90.7998 371.042 90.7998 371.042Z"
        fill="#F29788"
      />
      <path
        d="M27.9151 161.102H62.2775H94.6584C104.044 199.315 96.327 331.108 93.9284 372.398H73.2276L61.5996 213.078H61.026L49.3981 372.398H28.6973C26.2466 331.108 18.5815 199.315 27.9151 161.102Z"
        fill="#777A80"
      />
      <path
        d="M61.3389 185.396C45.8524 185.396 31.2002 183.154 24.9951 182.059C25.673 173.561 26.6637 166.419 27.9673 161.049H62.3296H94.7105C96.0141 166.367 97.0048 173.561 97.6827 182.059C91.4255 183.206 76.8254 185.448 61.3389 185.396Z"
        fill="#515559"
      />
      <path
        d="M6.79716 140.196L7.73574 104.641L29.2709 107.352C24.7344 123.565 16.7565 149.684 16.7565 155.836C16.7565 162.144 13.1065 182.528 11.1772 191.912L15.5572 199.68C16.4958 201.348 17.0172 203.173 17.1737 205.05L17.9037 214.903C17.9558 215.476 17.5387 215.998 16.9651 216.154C16.3915 216.311 15.7658 215.946 15.5572 215.372L13.3672 208.803L12.8458 210.68L18.0079 225.434C18.2687 226.164 17.9037 226.998 17.2258 227.311L16.4437 227.676L16.6001 228.197C16.8087 228.979 16.3915 229.761 15.6615 230.022C14.9315 230.282 14.1494 229.97 13.7843 229.344L4.45072 212.661C3.92929 211.671 3.61643 210.628 3.51214 209.533L2.67785 198.637H2.62571C2.62571 198.637 2.62571 198.533 2.62571 198.325L2.72999 191.339C2.72999 187.064 2.57356 181.851 2.10428 173.249C1.06141 154.428 6.79716 140.196 6.79716 140.196Z"
        fill="#FCC3B4"
      />
      <path
        d="M15.9222 227.467C15.9743 227.467 16.0265 227.467 16.1308 227.415C16.3915 227.311 16.5479 226.998 16.4436 226.737L10.8643 212.557C11.2815 211.306 13.4193 204.424 13.1065 200.931C13.1065 200.671 12.8458 200.41 12.5329 200.462C12.2722 200.462 12.0115 200.723 12.0636 201.036C12.3765 204.633 9.82146 212.348 9.82146 212.453C9.76931 212.557 9.76931 212.713 9.82146 212.818L15.4529 227.206C15.5572 227.363 15.7136 227.467 15.9222 227.467Z"
        fill="#F29788"
      />
      <path
        d="M25.5166 121.011L7.52716 113.764L7.68359 108.551C11.6465 109.437 19.4679 111.158 19.3115 111.262C19.1551 111.366 24.3694 114.234 26.9766 115.641C26.5073 117.414 25.9858 119.186 25.5166 121.011Z"
        fill="#F29788"
      />
      <path
        d="M115.151 140.196L114.212 104.641L92.6769 107.352C97.2134 123.565 105.191 149.684 105.191 155.836C105.191 162.144 108.841 182.528 110.771 191.912L106.391 199.68C105.452 201.348 104.931 203.173 104.774 205.05L104.044 214.903C103.992 215.476 104.409 215.998 104.983 216.154C105.556 216.311 106.182 215.946 106.391 215.372L108.581 208.803L109.102 210.68L103.94 225.434C103.679 226.164 104.044 226.998 104.722 227.311L105.504 227.676L105.348 228.197C105.139 228.979 105.556 229.761 106.286 230.022C107.016 230.282 107.798 229.97 108.163 229.344L117.445 212.557C117.966 211.566 118.279 210.524 118.384 209.429L119.218 198.533H119.27C119.27 198.533 119.27 198.429 119.27 198.22L119.166 191.235C119.166 186.96 119.322 181.746 119.791 173.144C120.886 154.428 115.151 140.196 115.151 140.196Z"
        fill="#FCC3B4"
      />
      <path
        d="M106.078 227.467C106.286 227.467 106.495 227.363 106.547 227.154L112.179 212.766C112.231 212.661 112.231 212.505 112.179 212.401C112.179 212.349 109.623 204.581 109.936 200.983C109.936 200.723 109.728 200.462 109.467 200.41C109.154 200.358 108.946 200.619 108.893 200.879C108.581 204.424 110.771 211.306 111.136 212.505L105.608 226.685C105.504 226.946 105.661 227.259 105.921 227.363C105.921 227.467 105.973 227.467 106.078 227.467Z"
        fill="#F29788"
      />
      <path
        d="M96.4834 121.011L114.473 113.764L114.316 108.551C110.354 109.437 102.532 111.158 102.688 111.262C102.845 111.366 97.6306 114.234 95.0234 115.641C95.4927 117.414 96.0142 119.186 96.4834 121.011Z"
        fill="#F29788"
      />
      <path
        d="M74.0097 55.4271L73.019 63.0907H48.9289C50.0239 61.631 50.8582 59.9627 51.3796 58.1902C52.5789 54.1759 53.3089 48.8583 50.2846 45.0526C49.5546 44.1663 49.8674 42.9151 49.8674 42.9151C49.6067 41.3511 49.6589 41.8724 49.2417 40.3606C49.0331 40.152 48.7724 39.9435 48.5639 39.6828C40.7945 31.6543 41.2117 18.8816 48.4596 10.6446C51.3274 7.36018 55.186 4.90992 59.5139 4.02365C69.9426 1.83405 81.2055 8.97632 84.3862 18.9859C84.4384 19.1944 84.4905 19.3508 84.5427 19.5594C84.8555 20.6542 85.1162 21.749 85.2205 22.8959C85.3769 23.9386 85.5855 25.4504 85.8462 27.223C86.0027 28.5263 86.2112 29.986 86.3677 31.55C87.202 35.7728 88.7663 37.3368 89.6527 38.7444C89.8613 39.0051 90.0177 39.3179 90.0177 39.6307C90.0177 40.2042 89.6005 40.7255 89.027 40.8819L87.2541 41.4032C87.2541 41.9767 87.3062 42.6023 87.2541 43.28C87.4105 47.972 87.1498 52.1427 86.1069 54.3323C85.7941 55.01 85.2205 55.5835 84.5427 55.9484C80.5798 58.138 74.0097 55.4271 74.0097 55.4271Z"
        fill="#FCC3B4"
      />
      <path
        d="M84.3341 27.6402C84.8555 27.588 85.4291 27.588 85.9505 27.6402C86.0027 28.1094 86.0548 28.5786 86.1591 29.0478C85.6376 28.9435 85.0641 28.8914 84.5426 28.9435C83.7084 29.0478 82.8741 29.4127 82.1962 29.934C82.0919 30.0383 81.9355 30.0904 81.7791 30.0904C81.5705 30.0904 81.3619 29.9862 81.2576 29.8298C80.9969 29.517 81.0491 29.0999 81.3619 28.8914C82.1962 28.2136 83.2391 27.7966 84.3341 27.6402Z"
        fill="#333333"
      />
      <path
        d="M86.1069 31.2373C86.2112 31.2373 86.2633 31.2373 86.3676 31.2894C86.3676 31.3937 86.4198 31.498 86.4198 31.6544C86.5762 32.3321 86.7326 32.9577 86.8891 33.5312C86.6805 33.6876 86.4198 33.7918 86.1069 33.7918C85.429 33.7918 84.8555 33.2184 84.8555 32.5406C84.8555 31.8629 85.429 31.2373 86.1069 31.2373Z"
        fill="#333333"
      />
      <path
        d="M85.1684 44.2707C85.1684 44.2707 85.7941 44.6356 87.3584 45.0527C87.3584 45.4176 87.3584 45.7826 87.3584 46.1475C85.4291 45.6783 84.6991 45.2091 84.6469 45.2091C84.3862 45.0527 84.3341 44.7399 84.4905 44.4792C84.6469 44.1664 84.9598 44.1143 85.1684 44.2707Z"
        fill="#F29788"
      />
      <path
        d="M81.6226 34.3653C81.7269 34.3653 81.8312 34.3131 81.9355 34.261L83.7605 32.9055C83.9169 32.8013 84.0212 32.5927 83.9691 32.3842C83.9169 32.1757 83.7605 32.0193 83.5519 31.9671L80.4233 31.3415C80.1626 31.2894 79.8497 31.4979 79.7976 31.7586C79.7455 32.0193 79.954 32.3321 80.2147 32.3842L82.1962 32.7491L81.3098 33.4269C81.1012 33.5833 81.049 33.9482 81.2055 34.1567C81.3098 34.3131 81.4662 34.3653 81.6226 34.3653Z"
        fill="#F29788"
      />
      <path
        d="M75.0004 55.7921C75.0526 55.7399 75.0526 55.6878 75.0526 55.5835L75.3133 52.5077C75.3133 52.1949 75.1047 51.9863 74.844 51.9342C74.5833 51.8821 74.3226 52.1427 74.2704 52.4034L74.0097 55.4271C74.114 55.4793 74.479 55.6357 75.0004 55.7921Z"
        fill="#F29788"
      />
      <path
        d="M40.1166 13.512L44.3402 12.9907L44.0795 9.60202L47.6253 11.6352C48.1988 10.9575 48.8245 10.2798 49.5024 9.65416C49.5546 9.60202 49.6067 9.54989 49.6588 9.49776C52.3703 6.83896 55.8117 4.91003 59.5139 4.07589C61.7039 3.60669 63.9982 3.55456 66.1882 3.86736C69.6297 2.25123 72.7061 0.89576 72.8104 1.00003C72.8626 1.10429 72.1847 2.45976 71.1418 4.28443L84.8555 4.70149L83.8126 8.87216L88.7662 6.99536C87.7234 11.2703 85.1683 16.0666 79.0154 17.8391L86.7848 22.3226C86.7848 22.3226 81.2055 22.3226 76.2519 21.0714L74.5833 38.4839L70.1511 38.5881L70.0468 35.8772C67.1789 38.8488 59.6182 45.6261 50.7538 45.574C50.5974 45.3655 50.4931 45.1569 50.3367 44.9484C50.2846 44.8963 36.7273 30.9245 44.4445 16.1187L40.1166 13.512Z"
        fill="#333333"
      />
      <path
        d="M61.6518 39.2139C60.4003 36.2944 61.756 32.9579 64.6761 31.7067C67.5961 30.4555 70.9332 31.8631 72.1847 34.7304C73.4361 37.6499 72.0804 40.9864 69.1604 42.2376C66.2403 43.4888 62.9032 42.1333 61.6518 39.2139Z"
        fill="#FCC3B4"
      />
      <path
        d="M63.6854 38.8487C63.7375 38.8487 63.8418 38.8487 63.8939 38.7966C64.1547 38.6923 64.2589 38.3795 64.1547 38.1189C63.8418 37.389 63.8418 36.607 64.1025 35.8771C64.4154 35.1473 64.9368 34.5738 65.6668 34.261C66.1882 34.0525 66.7097 34.0003 67.2832 34.0525C67.544 34.1046 67.8568 33.8961 67.8568 33.6354C67.909 33.3747 67.7004 33.0619 67.4397 33.0619C66.7097 32.9577 65.9797 33.0619 65.2497 33.3226C64.2589 33.7397 63.5289 34.5217 63.1118 35.5122C62.6947 36.5027 62.7468 37.5975 63.1639 38.5881C63.3204 38.6923 63.5289 38.8487 63.6854 38.8487Z"
        fill="#F29788"
      />
      <path
        d="M63.4246 41.5076C64.3632 42.2374 65.4582 42.6545 66.6575 42.7066C66.9704 42.7066 67.0225 43.1758 66.7618 43.2801C65.9275 43.5408 64.5718 43.5408 62.5903 42.1332"
        fill="#F29788"
      />
      <path
        d="M29.5316 117.257L28.593 119.864L6.01501 110.636L8.30931 93.3281C8.51789 85.1431 15.6615 77.9487 23.1701 74.2473L46.9996 63.5078H75.4697L99.2992 74.2473C106.756 77.9487 113.951 85.1431 114.16 93.3281L116.454 110.636L93.8241 119.916L93.042 117.257C92.2077 131.02 93.6155 151.874 98.6734 178.253C98.6734 178.253 80.7362 182.007 61.2868 181.955C41.8374 181.955 23.9001 178.253 23.9001 178.253C29.0102 151.561 30.418 131.073 29.5316 117.257Z"
        fill="#EDEFF2"
      />
      <path
        d="M61.4953 74.56C72.4454 74.56 81.7269 69.9723 82.144 69.7638L83.6562 68.9818C83.9169 68.8775 84.0212 68.5647 83.8647 68.304C83.7605 68.0434 83.4476 67.9391 83.1869 68.0955L81.6747 68.8775C81.3097 69.086 72.2368 73.5695 61.4953 73.5695C54.1953 73.5695 47.8338 71.4842 42.5674 67.4178C42.3588 67.2614 41.9938 67.2614 41.8373 67.522C41.6809 67.7306 41.6809 68.0955 41.9416 68.2519C47.3645 72.4226 53.9867 74.56 61.4953 74.56Z"
        fill="#C4C7CC"
      />
      <path
        d="M69.8383 56.8347L74.0097 55.2707L73.4883 58.8158C73.2797 60.4841 74.114 62.1002 75.5219 62.9343L81.9876 66.4794C81.9876 66.4794 59.2011 77.8445 43.0367 65.3325L48.1989 62.7258C49.0853 62.2566 49.8153 61.5267 50.1803 60.5883C51.4839 57.3561 54.0389 50.6309 50.806 45.7825"
        fill="#FCC3B4"
      />
      <path
        d="M29.5316 117.779C29.7923 117.779 30.0009 117.622 30.053 117.362L32.0866 107.456C32.1388 107.196 31.9823 106.883 31.6695 106.831C31.4088 106.779 31.0959 106.935 31.0438 107.248L29.0102 117.153C28.958 117.414 29.1145 117.727 29.4273 117.779C29.4795 117.779 29.5316 117.779 29.5316 117.779Z"
        fill="#C4C7CC"
      />
      <path
        d="M29.5316 117.779H29.5837C29.8444 117.727 30.053 117.466 30.053 117.205L28.3323 104.589C28.2801 104.328 28.0194 104.12 27.7587 104.12C27.498 104.172 27.2894 104.433 27.2894 104.693L29.0102 117.31C29.0623 117.622 29.2709 117.779 29.5316 117.779Z"
        fill="#C4C7CC"
      />
      <path
        d="M93.042 117.779C93.0941 117.779 93.0941 117.779 93.1463 117.779C93.407 117.727 93.6156 117.466 93.5634 117.153L91.5298 107.248C91.4777 106.987 91.217 106.779 90.9041 106.831C90.6434 106.883 90.4348 107.144 90.487 107.456L92.5206 117.362C92.6249 117.622 92.8334 117.779 93.042 117.779Z"
        fill="#C4C7CC"
      />
      <path
        d="M93.042 117.779C93.3027 117.779 93.5113 117.57 93.5634 117.31L95.2841 104.693C95.3363 104.433 95.1277 104.12 94.8149 104.12C94.5541 104.068 94.2413 104.276 94.2413 104.589L92.5206 117.205C92.4684 117.466 92.677 117.779 92.9898 117.779H93.042Z"
        fill="#C4C7CC"
      />
      <path
        d="M59.0446 213.6C59.2532 213.6 59.4096 213.495 59.5139 213.339C59.6704 213.078 59.5661 212.766 59.3053 212.609C58.9925 212.453 51.6924 208.386 39.6474 207.344C39.3345 207.291 39.1259 207.552 39.0738 207.813C39.0738 208.074 39.2824 208.334 39.5431 208.386C51.3796 209.429 58.7318 213.495 58.7839 213.495C58.8882 213.548 58.9925 213.6 59.0446 213.6Z"
        fill="#515559"
      />
      <path
        d="M59.0968 213.6C59.3054 213.6 59.5661 213.443 59.6182 213.183C59.6704 212.922 59.5139 212.609 59.2532 212.557C59.0968 212.505 55.2903 211.566 49.0332 211.671C48.7203 211.671 48.5117 211.931 48.5117 212.192C48.5117 212.453 48.7724 212.713 49.0332 212.713C55.1339 212.661 58.9403 213.547 58.9925 213.6C58.9925 213.547 59.0446 213.6 59.0968 213.6Z"
        fill="#515559"
      />
      <path
        d="M63.0596 213.6C63.1639 213.6 63.2161 213.6 63.3203 213.548C63.3725 213.496 70.7768 209.429 82.5612 208.439C82.874 208.439 83.0826 208.178 83.0305 207.865C83.0305 207.552 82.7698 207.344 82.4569 207.396C70.4118 208.439 63.1118 212.505 62.7989 212.661C62.5382 212.818 62.4339 213.131 62.5903 213.391C62.6946 213.496 62.9032 213.6 63.0596 213.6Z"
        fill="#515559"
      />
      <path
        d="M63.0597 213.6C63.1118 213.6 63.1639 213.6 63.1639 213.6C63.2161 213.6 67.0225 212.661 73.1233 212.713C73.384 212.713 73.6447 212.505 73.6447 212.192C73.6447 211.879 73.4361 211.671 73.1233 211.671C66.9182 211.619 63.0597 212.505 62.9032 212.557C62.6425 212.609 62.4339 212.922 62.5382 213.183C62.6425 213.443 62.8511 213.6 63.0597 213.6Z"
        fill="#515559"
      />
      <path
        d="M33.3902 293.676H45.9567C46.2696 293.676 46.4781 293.468 46.4781 293.155C46.4781 292.842 46.2696 292.634 45.9567 292.634H33.3902C33.0773 292.634 32.8688 292.842 32.8688 293.155C32.8688 293.468 33.1295 293.676 33.3902 293.676Z"
        fill="#515559"
      />
      <path
        d="M76.2518 293.676H88.8183C89.1312 293.676 89.3398 293.468 89.3398 293.155C89.3398 292.842 89.1312 292.634 88.8183 292.634H76.2518C75.939 292.634 75.7304 292.842 75.7304 293.155C75.7304 293.468 75.939 293.676 76.2518 293.676Z"
        fill="#515559"
      />
      <path
        d="M9.8736 145.201C8.72645 145.044 8.04859 143.376 8.36145 141.291C8.46573 140.405 8.77859 139.57 9.19574 138.945C9.66503 138.215 10.2907 137.85 10.8643 137.954C11.49 138.059 11.9593 138.528 12.22 139.362C12.4286 140.04 12.4808 140.926 12.3765 141.864C12.2722 142.751 11.9593 143.585 11.5422 144.21C11.125 144.888 10.6036 145.201 10.03 145.201C9.97788 145.201 9.92574 145.201 9.8736 145.201ZM10.03 139.518C9.71717 139.987 9.45645 140.717 9.35217 141.447C9.14359 143.011 9.61288 144.106 9.97788 144.158C10.1343 144.158 10.4472 143.95 10.6557 143.637C10.9686 143.168 11.2293 142.438 11.3336 141.708C11.4379 140.978 11.3857 140.248 11.2293 139.675C11.125 139.258 10.9165 138.997 10.7079 138.997C10.7079 138.997 10.7079 138.997 10.6557 138.997C10.4993 138.945 10.2386 139.153 10.03 139.518Z"
        fill="#F29788"
      />
      <path
        d="M110.301 144.158C109.884 143.532 109.623 142.698 109.467 141.812C109.363 140.926 109.415 140.039 109.623 139.31C109.884 138.475 110.353 138.006 110.979 137.902C111.605 137.798 112.179 138.163 112.648 138.893C113.065 139.518 113.326 140.352 113.482 141.239C113.743 143.324 113.117 144.992 111.97 145.149C111.918 145.149 111.866 145.149 111.814 145.149C111.292 145.201 110.771 144.836 110.301 144.158ZM111.136 138.945C110.979 138.945 110.771 139.257 110.614 139.622C110.458 140.144 110.406 140.926 110.51 141.656C110.614 142.385 110.875 143.115 111.188 143.585C111.396 143.949 111.709 144.158 111.866 144.106C112.231 144.054 112.7 142.959 112.491 141.395C112.387 140.665 112.126 139.935 111.814 139.466C111.605 139.153 111.344 138.945 111.136 138.945C111.188 138.945 111.188 138.945 111.136 138.945Z"
        fill="#F29788"
      />
      <path
        d="M113.482 192.642C113.743 192.642 114.004 192.434 114.004 192.173C114.004 191.912 113.795 191.652 113.534 191.599L111.449 191.443C111.136 191.391 110.927 191.652 110.875 191.912C110.875 192.173 111.084 192.434 111.344 192.486L113.482 192.642Z"
        fill="#F29788"
      />
      <path
        d="M8.67431 192.642C8.72646 192.642 8.72646 192.642 8.67431 192.642L10.8122 192.486C11.125 192.486 11.3336 192.225 11.2815 191.912C11.2815 191.599 11.0208 191.391 10.7079 191.443L8.62217 191.599C8.30931 191.599 8.10074 191.86 8.15288 192.173C8.20503 192.434 8.4136 192.642 8.67431 192.642Z"
        fill="#F29788"
      />
      <g data-type="ankle-or-foot">
        <circle
          cx="83.6472"
          cy="390"
          r="7"
          transform-origin="83.6472px 390px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="83.6472"
          cy="390"
          r="7"
          transform-origin="83.6472px 390px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="83.6472"
          cy="390"
          r="7"
        />

        <circle
          cx="39"
          cy="390"
          r="7"
          transform-origin="39px 390px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="39"
          cy="390"
          r="7"
          transform-origin="39px 390px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="39"
          cy="390"
          r="7"
        />
      </g>
      <g data-type="knee">
        <circle
          cx="40"
          cy="293"
          r="9.69231"
          transform-origin="40px 293px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="40"
          cy="293"
          r="9.69231"
          transform-origin="40px 293px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="40"
          cy="293"
          r="9.69231"
        />

        <circle
          cx="83"
          cy="293"
          r="9.69231"
          transform-origin="83px 293px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="83"
          cy="293"
          r="9.69231"
          transform-origin="83px 293px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="83"
          cy="293"
          r="9.69231"
        />
      </g>
      <g data-type="hip">
        <circle
          cx="41.3235"
          cy="194.324"
          r="8.78959"
          transform-origin="41.3235px 194.324px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="41.3235"
          cy="194.324"
          r="8.78959"
          transform-origin="41.3235px 194.324px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="41.3235"
          cy="194.324"
          r="8.78959"
        />

        <circle
          cx="80.9707"
          cy="194.324"
          r="8.78959"
          transform-origin="80.9707px 194.324px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="80.9707"
          cy="194.324"
          r="8.78959"
          transform-origin="80.9707px 194.324px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="80.9707"
          cy="194.324"
          r="8.78959"
        />
      </g>
      <g data-type="hand-or-wrist">
        <circle
          cx="113.647"
          cy="198"
          r="7"
          transform-origin="113.647px 198px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="113.647"
          cy="198"
          r="7"
          transform-origin="113.647px 198px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="113.647"
          cy="198"
          r="7"
        />

        <circle
          cx="7"
          cy="198"
          r="7"
          transform-origin="7px 198px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="7"
          cy="198"
          r="7"
          transform-origin="7px 198px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="7"
          cy="198"
          r="7"
        />
      </g>
      <g data-type="elbow">
        <circle
          cx="109.676"
          cy="141.324"
          r="6.32423"
          transform-origin="109.676px 141.324px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="109.676"
          cy="141.324"
          r="6.32423"
          transform-origin="109.676px 141.324px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="109.676"
          cy="141.324"
          r="6.32423"
        />

        <circle
          cx="12.3242"
          cy="141.324"
          r="6.32423"
          transform-origin="12.3242px 141.324px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="12.3242"
          cy="141.324"
          r="6.32423"
          transform-origin="12.3242px 141.324px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="12.3242"
          cy="141.324"
          r="6.32423"
        />
      </g>
      <g data-type="lower-back">
        <circle
          cx="61"
          cy="167"
          r="12.4"
          transform-origin="61px 167px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="61"
          cy="167"
          r="12.4"
          transform-origin="61px 167px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="61"
          cy="167"
          r="12.4"
        />
      </g>
      <g data-type="upper-back">
        <circle
          cx="61"
          cy="105.5"
          r="12.2"
          transform-origin="61px 105.5px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="61"
          cy="105.5"
          r="12.2"
          transform-origin="61px 105.5px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="61"
          cy="105.5"
          r="12.2"
        />
      </g>
      <g data-type="shoulder">
        <circle
          cx="24"
          cy="86"
          r="7"
          transform-origin="24px 86px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="24"
          cy="86"
          r="7"
          transform-origin="24px 86px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="24"
          cy="86"
          r="7"
        />

        <circle
          cx="98"
          cy="86"
          r="7"
          transform-origin="98px 86px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="98"
          cy="86"
          r="7"
          transform-origin="98px 86px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="98"
          cy="86"
          r="7"
        />
      </g>
      <g data-type="neck">
        <circle
          cx="62"
          cy="58"
          r="7"
          transform-origin="62px 58px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="62"
          cy="58"
          r="7"
          transform-origin="62px 58px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="62"
          cy="58"
          r="7"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'PainMaleFigure',
  props: {
    bodyParts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClickEvent(event) {
      let { target } = event
      let dataType = target.getAttribute('data-type')

      while (dataType == null) {
        target = target.parentNode
        dataType = target.getAttribute('data-type')
        if (target.nodeName.toLowerCase() === 'svg') {
          break
        }
      }
    },
  },
}
</script>
<style lang="scss">
[data-type='ankle-or-foot'],
[data-type='knee'],
[data-type='hip'],
[data-type='hand-or-wrist'],
[data-type='elbow'],
[data-type='lower-back'],
[data-type='upper-back'],
[data-type='shoulder'],
[data-type='neck'] {
  fill: transparent;
  cursor: pointer;
}

.non-interactive [data-type] {
  cursor: default;
}

.transparent [data-type] {
  opacity: 0.5;
}

@keyframes figure-pulsation-inner {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    opacity: 0.25;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes figure-pulsation-outer {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    opacity: 0.15;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.ankle-or-foot [data-type='ankle-or-foot'],
.knee [data-type='knee'],
.hip [data-type='hip'],
.hand-or-wrist [data-type='hand-or-wrist'],
.elbow [data-type='elbow'],
.lower-back [data-type='lower-back'],
.upper-back [data-type='upper-back'],
.shoulder [data-type='shoulder'],
.neck [data-type='neck'] {
  fill: #ff375b;
}

.figure-pulsation-inner,
.figure-pulsation-outer {
  opacity: 0;
  transform: scale(1);
}

.ankle--worst [data-type='ankle-or-foot'],
.foot--worst [data-type='ankle-or-foot'],
.knee--worst [data-type='knee'],
.hip--worst [data-type='hip'],
.hand--worst [data-type='hand-or-wrist'],
.wrist--worst [data-type='hand-or-wrist'],
.elbow--worst [data-type='elbow'],
.lower-back--worst [data-type='lower-back'],
.upper-back--worst [data-type='upper-back'],
.neck--worst [data-type='neck'],
.shoulder--worst [data-type='shoulder'] {
  opacity: 1;
  fill: #ff375b;

  .figure-pulsation-inner,
  .figure-pulsation-outer {
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .figure-pulsation-inner {
    animation-name: figure-pulsation-inner;
  }

  .figure-pulsation-outer {
    animation-name: figure-pulsation-outer;
  }
}
</style>
