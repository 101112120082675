import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { getDefaultLanguage } from '@shared/utils'
import { sessionStorage } from '@shared/helpers/storage'
import assessmentModuleStore from '@shared/modules/assessment/state'
import b2bStore from '@msk-us/state/b2b'
import actions from '@msk-us/state/actions'

export default new Vuex.Store({
  strict: import.meta.env.NODE_ENV !== 'production',
  state: {
    /** @type {User} */
    user: null,
    country: 'US',
    lang: getDefaultLanguage(),
    checkInStatus: '',
  },
  actions,
  getters: {
    user: (state) => state.user,
    country: (state) => state.country,
    lang: (state) => state.lang,
    checkInStatus: (state) => state.checkInStatus,
  },
  mutations: {
    user(state, value) {
      state.user = value
    },
    lang(state, value) {
      state.lang = value
    },
    setCheckInStatus(state, value) {
      state.checkInStatus = value
    },
  },
  modules: {
    assessment: assessmentModuleStore,
    b2b: b2bStore,
  },
  plugins: [
    new VuexPersistence({
      storage: sessionStorage,
    }).plugin,
  ],
})
