<template>
  <div class="quiz-results-theme quiz-content__inner cat-results">
    <QuizNavHeader
      :stage="currentQuestionnaire"
      :stage-count="questionnaireCount"
      :stage-progress="100"
      :title="$t('b2b_quiz_cat_completed_title')"
      :back-button="false"
    />

    <QuizContentContainer class="results-container-inner">
      <img
        class="icon"
        src="@shared/assets/img/b2b/union-icon.svg"
      />
      <h3 class="title">{{ $t(catSummary.summary.title) }}</h3>
      <div class="score">
        <span :class="catSummary.summary.group">{{ catSummary.score }}</span>
        <span class="score__max"> {{ $t('b2b_quiz_cat_of') }} 40</span>
        <p class="graphic">
          <img src="@shared/assets/img/cat-score.svg" />
          <span>{{ $t('b2b_quiz_cat_legend_0') }}</span>
          <span>{{ $t('b2b_quiz_cat_legend_40') }}</span>
        </p>
      </div>
      <div class="recommendations-list">
        <div
          v-for="(item, index) in catSummary.summary.recommendations"
          :key="index"
        >
          <h5>{{ $t(item.title) }}</h5>
          <p>{{ $t(item.recommendation) }}</p>
        </div>
      </div>
      <BaseButton
        data-qa="submit"
        @click="nextSurvey"
      >
        {{ $t('generic_action_continue') }}
      </BaseButton>
    </QuizContentContainer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import BaseButton from '@shared/components/BaseButton.vue'
import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'
import QuizContentContainer from '@shared/components/Quiz/QuizContentContainer.vue'

const router = useRouter()
const store = useStore()

const currentQuestionnaire = computed(
  () => store.getters['assessment/currentQuestionnaire'],
)
const questionnaireCount = computed(
  () => store.getters['assessment/questionnaireCount'],
)
const catSummary = computed(() => store.getters['assessment/catSummary'])

const nextSurvey = () => {
  store.dispatch('assessment/completeQuestionnaire', { router })
}
</script>

<style lang="scss" scoped>
.quiz-results-theme {
  .results-container-inner {
    margin-top: 30px;

    .score {
      font-size: 24px;
      text-align: center;

      .low {
        color: $green;
      }
      .medium {
        color: $blue;
      }
      .high {
        color: $yellow;
      }
      .very-high {
        color: $red_pain;
      }
      .graphic {
        img {
          max-width: 100%;
        }
        span {
          text-align: left;
          display: inline-block;
          font-size: 14px;
          width: 50%;
        }
        span:last-child {
          text-align: right;
        }
      }

      &__max {
        margin-left: 6px;
      }
    }
  }

  &.cat-results {
    .icon {
      display: block;
      margin: 0 auto 30px;
    }

    h3.title {
      text-align: center;
      font-size: 40px;
      margin: 0 0 20px;
      font-weight: bold;
      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 10px;
        font-size: 40px !important;
      }
    }
  }
}

[data-qa='submit'] {
  margin-top: 20px;
}
</style>
