import ROUTE from '@shared/modules/assessment/router/names'

export default [
  {
    path: '/assessment',
    redirect: '/assessment/login',
  },
  {
    path: '/assessment/login',
    name: ROUTE.ASSESSMENT_LOGIN,
    component: () => import('@shared/modules/assessment/views/Login.vue'),
    meta: {
      layout: 'B2BLayout',
    },
  },
  {
    path: '/assessment/self-test',
    component: () =>
      import(
        '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTest.vue'
      ),
    children: [
      {
        path: 'welcome',
        name: ROUTE.ASSESSMENT_SELF_TEST_WELCOME,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestWelcome.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_get_started',
        },
      },
      {
        path: 'precautions-msk',
        name: ROUTE.ASSESSMENT_SELF_TEST_PRECAUTIONS_MSK,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestPrecautions_MSK_EU.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_precautions',
        },
      },
      {
        path: 'precautions-copd',
        name: ROUTE.ASSESSMENT_SELF_TEST_PRECAUTIONS_COPD,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestPrecautions_COPD.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_precautions',
        },
      },
      {
        path: 'about',
        name: ROUTE.ASSESSMENT_SELF_TEST_ABOUT,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestAboutDE.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_basic_info',
        },
      },
      {
        path: 'activities',
        name: ROUTE.ASSESSMENT_SELF_TEST_ACTIVITIES,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestActivities.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_physical_activity',
        },
      },
      {
        path: 'worst-pain-location',
        name: ROUTE.ASSESSMENT_SELF_TEST_WORST_PAIN_LOCATION,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestWorstPainLocation.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_pain_locations',
        },
      },
      {
        path: 'pain-duration',
        name: ROUTE.ASSESSMENT_SELF_TEST_PAIN_DURATION,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestPainDuration.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_pain_duration',
        },
      },
      {
        path: 'pain-intensity',
        name: ROUTE.ASSESSMENT_SELF_TEST_PAIN_INTENSITY,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestPainIntensity.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_pain_intensity_24',
        },
      },
      {
        path: 'fear-of-movement',
        name: ROUTE.ASSESSMENT_SELF_TEST_FEAR_OF_MOVEMENT,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestFearOfMovement.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_fear_of_movement',
        },
      },
      {
        path: 'commitment-intro',
        name: ROUTE.ASSESSMENT_SELF_TEST_COMMITMENT_INTRO,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTest/SelfTestCommitmentIntro.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'onb_finish_congratulations',
        },
      },
      {
        path: '',
        redirect: 'welcome',
      },
    ],
  },
  {
    path: '/assessment/cat',
    component: () =>
      import('@shared/modules/assessment/views/QuestionnaireCAT/CAT.vue'),
    children: [
      {
        path: 'welcome',
        name: ROUTE.ASSESSMENT_CAT_WELCOME,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireCAT/CATWelcome.vue'
          ),
        meta: {
          layout: 'B2BLayout',
        },
      },
      {
        path: 'questions',
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireCAT/CATList/index.vue'
          ),
        name: ROUTE.ASSESSMENT_CAT_LIST,
        meta: {
          layout: 'B2BLayout',
        },
      },
      {
        path: 'completed',
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireCAT/CATCompleted.vue'
          ),
        name: ROUTE.ASSESSMENT_CAT_COMPLETED,
        meta: {
          layout: 'B2BLayout',
        },
      },
      {
        path: '',
        redirect: 'welcome',
      },
    ],
  },
  {
    path: '/assessment/self-test-reevaluations',
    redirect: '/assessment/self-test-reevaluations/pain-average',
  },
  {
    path: '/assessment/self-test-reevaluations',
    component: () =>
      import(
        '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluations.vue'
      ),
    children: [
      {
        path: 'pain-average',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPainAverage.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_pain_average',
        },
      },
      {
        path: 'pain-worst',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_WORST,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPainWorst.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_pain_worst',
        },
      },
      {
        path: 'pain-now',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_NOW,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPainNow.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_pain_now',
        },
      },
      {
        path: 'physical-activity-chores',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_CHORES,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPhysicalActivityChores.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_physical_activity_chores',
        },
      },
      {
        path: 'physical-activity-stairs',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_STAIRS,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPhysicalActivityStairs.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_physical_activity_stairs',
        },
      },
      {
        path: 'physical-activity-walk',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_WALK,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPhysicalActivityWalk.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_physical_activity_walk',
        },
      },
      {
        path: 'physical-activity-errands',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_ERRANDS,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPhysicalActivityErrands.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_physical_activity_errands',
        },
      },
      {
        path: 'fear-of-movement',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_FEAR_OF_MOVEMENT,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsFearOfMovement.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_fear_of_movement',
        },
      },
      {
        path: 'productivity',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PRODUCTIVITY,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsProductivity.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_productivity',
        },
      },
      {
        path: 'confidence',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_CONFIDENCE,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsConfidence.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_confidence',
        },
      },
      {
        path: 'phq-interest',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_INTEREST,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_phq_interest',
        },
      },
      {
        path: 'phq-feeling',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_FEELING,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_phq_feeling',
        },
      },
      {
        path: 'gad-nervous',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_NERVOUS,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_nervous',
        },
      },
      {
        path: 'gad-control',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_CONTROL,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_control',
        },
      },
      {
        path: 'gad-worrying',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_WORRYING,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_worrying',
        },
      },
      {
        path: 'gad-relaxing',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RELAXING,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_relaxing',
        },
      },
      {
        path: 'gad-restless',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RESTLESS,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_restless',
        },
      },
      {
        path: 'gad-irritable',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_IRRITABLE,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_irritable',
        },
      },
      {
        path: 'gad-afraid',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_AFRAID,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsRadioColumnView.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_gad_afraid',
        },
      },
      {
        path: 'past-consultations',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_CONSULTATIONS,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPastConsultations.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_past_consultations',
        },
      },
      {
        path: 'past-medications',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_MEDICATIONS,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsPastMedication.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_past_medications',
        },
      },
      {
        path: 'complete',
        name: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_COMPLETE,
        component: () =>
          import(
            '@shared/modules/assessment/views/QuestionnaireSelfTestReevaluations/SelfTestReevaluationsComplete.vue'
          ),
        meta: {
          layout: 'B2BLayout',
          name: 'monthly_checkup_complete',
        },
      },
    ],
  },
  {
    path: '/assessment/completed',
    name: ROUTE.ASSESSMENT_COMPLETION,
    component: () => import('@shared/modules/assessment/views/Completion.vue'),
    meta: {
      layout: 'B2BLayout',
    },
  },
]
