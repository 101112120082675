export default {
  ASSESSMENT_LOGIN: 'assessment-login',
  ASSESSMENT_COMPLETION: 'assessment-completion',

  ASSESSMENT_SELF_TEST_WELCOME: 'assessment-self-test-welcome',
  ASSESSMENT_SELF_TEST_ABOUT: 'assessment-self-test-about',
  ASSESSMENT_SELF_TEST_PRECAUTIONS_COPD:
    'assessment-self-test-precautions-copd',
  ASSESSMENT_SELF_TEST_PRECAUTIONS_MSK: 'assessment-self-test-precautions-msk',
  ASSESSMENT_SELF_TEST_PAIN_LOCATION: 'assessment-self-test-pain-location',
  ASSESSMENT_SELF_TEST_WORST_PAIN_LOCATION:
    'assessment-self-test-worst-pain-location',
  ASSESSMENT_SELF_TEST_PAIN_DURATION: 'assessment-self-test-pain-duration',
  ASSESSMENT_SELF_TEST_ACTIVITIES: 'assessment-self-test-activities',
  ASSESSMENT_SELF_TEST_PAIN_INTENSITY: 'assessment-self-test-pain-intensity',
  ASSESSMENT_SELF_TEST_FEAR_OF_MOVEMENT:
    'assessment-self-test-fear-of-movement',
  ASSESSMENT_SELF_TEST_COMMITMENT_INTRO:
    'assessment-self-test-commitment-intro',

  ASSESSMENT_CAT_WELCOME: 'assessment-cat-welcome',
  ASSESSMENT_CAT_LIST: 'assessment-cat-list',
  ASSESSMENT_CAT_COMPLETED: 'assessment-cat-completed',

  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE:
    'assessment-self-test-reevaluations-pain-average',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_WORST:
    'assessment-self-test-reevaluations-pain-worst',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_NOW:
    'assessment-self-test-reevaluations-pain-now',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_CHORES:
    'assessment-self-test-reevaluations-physical-activity-chores',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_STAIRS:
    'assessment-self-test-reevaluations-physical-activity-stairs',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_WALK:
    'assessment-self-test-reevaluations-physical-activity-walk',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHYSICAL_ACTIVITY_ERRANDS:
    'assessment-self-test-reevaluations-physical-activity-errands',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_FEAR_OF_MOVEMENT:
    'assessment-self-test-reevaluations-fear-of-movement',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PRODUCTIVITY:
    'assessment-self-test-reevaluations-productivity',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_CONFIDENCE:
    'assessment-self-test-reevaluations-confidence',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_INTEREST:
    'assessment-self-test-reevaluations-phq-interest',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_FEELING:
    'assessment-self-test-reevaluations-phq-feeling',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_NERVOUS:
    'assessment-self-test-reevaluations-gad-nervous',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_CONTROL:
    'assessment-self-test-reevaluations-gad-control',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_WORRYING:
    'assessment-self-test-reevaluations-gad-worrying',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RELAXING:
    'assessment-self-test-reevaluations-gad-relaxing',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RESTLESS:
    'assessment-self-test-reevaluations-gad-restless',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_IRRITABLE:
    'assessment-self-test-reevaluations-gad-irritable',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_AFRAID:
    'assessment-self-test-reevaluations-gad-afraid',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_CONSULTATIONS:
    'assessment-self-test-reevaluations-past-consultations',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_PAST_MEDICATIONS:
    'assessment-self-test-reevaluations-past-medications',
  ASSESSMENT_SELF_TEST_REEVALUATIONS_COMPLETE:
    'assessment-self-test-reevaluations-complete',
}
