<template>
  <div class="quiz-content__inner quiz-content__inner--padding-top">
    <CATList
      @back="back"
      @next="next"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ROUTE from '@shared/modules/assessment/router/names'
import { logE } from '@shared/utils'

import CATList from '@shared/modules/assessment/views/QuestionnaireCAT/CATList/CATList.vue'

const router = useRouter()
const store = useStore()

const catAnswers = computed(() => store.getters['assessment/catAnswers'])
const catQuestionsScore = computed(
  () => store.getters['assessment/catQuestionsScore'],
)
const catSummary = computed(() => store.getters['assessment/catSummary'])

const back = () => {
  router.push({ path: 'welcome' })
}

const next = async () => {
  try {
    await store.dispatch('assessment/submitCAT', {
      answers: catAnswers.value,
      score: catQuestionsScore.value,
      group: catSummary.value.summary.group,
    })
  } catch (error) {
    logE(error)
  } finally {
    await router.push({
      name: ROUTE.ASSESSMENT_CAT_COMPLETED,
    })
  }
}
</script>

<style lang="scss" scoped>
.cat-quiz-content {
  max-height: calc(100% - 5px);
  overflow-y: auto;
  @media (max-width: $breakpoint-tablet) {
    max-height: inherit;
    overflow-y: inherit;
  }
}
</style>
