<template>
  <div :class="[...cssClasses, 'pain_locations']">
    <PainMaleFigure
      v-if="gender !== 'f'"
      :body-parts="bodyParts"
    />
    <PainFemaleFigure
      v-if="gender === 'f'"
      :body-parts="bodyParts"
    />
  </div>
</template>

<script>
import PainMaleFigure from '@shared/components/PainMaleFigure.vue'
import PainFemaleFigure from '@shared/components/PainFemaleFigure.vue'

export default {
  name: 'PainLocations',
  components: {
    PainMaleFigure,
    PainFemaleFigure,
  },
  props: {
    cssClasses: {
      type: Array,
      default: () => [],
    },
    bodyParts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    gender() {
      return this.$store.getters['assessment/gender']
    },
  },
}
</script>

<style lang="scss" scoped>
.pain_locations {
  height: 423px;
  width: 141px;
}
</style>
