<template>
  <div class="welcome-theme">
    <QuizNavHeader
      :stage="currentQuestionnaire"
      :stage-count="questionnaireCount"
      :stage-progress="5"
      :title="$t('b2b_quiz_cat_welcome_title')"
      :back-button="false"
      :progress-info="$t('b2b_quiz_welcome_progress_info', { percentage })"
      :info-text="$t('b2b_quiz_cat_copyright')"
    />

    <QuizContentContainer>
      <h3
        class="progress-title"
        v-html="$t('b2b_quiz_welcome_part_info', partInfo)"
      />

      <h2
        class="page-title"
        v-html="$t('medical_profile_cat_title')"
      />

      <div class="welcome-text">
        <p v-html="$t('b2b_quiz_cat_welcome_content')" />
      </div>

      <div class="figure">
        <img
          src="@shared/assets/img/b2b/relaxation.svg"
          alt=""
        />
      </div>

      <BaseButton
        data-qa="submit"
        @click="startSurvey"
      >
        {{ $t('b2b_self_test_button_start') }}
      </BaseButton>
    </QuizContentContainer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import QuizContentContainer from '@shared/components/Quiz/QuizContentContainer.vue'

const router = useRouter()
const store = useStore()

const currentQuestionnaire = computed(
  () => store.getters['assessment/currentQuestionnaire'],
)

const questionnaireCount = computed(
  () => store.getters['assessment/questionnaireCount'],
)

const percentage = computed(() => store.getters['assessment/percentage'])
const partInfo = computed(() => store.getters['assessment/partInfo'])

const startSurvey = () => {
  router.push({
    path: 'questions',
  })
}
</script>

<style lang="scss">
.welcome-theme {
  .quiz-content-container {
    padding: 20px;
  }
}
</style>
