export default {
  state: {
    gender: null, // Enum [m, f, u]
    inProgress: false,
    worstPainLocation: '',
    fitnessActivities: [],
    chronificationLevels: [],
    redFlagApproved: null,
    painIntensity: -1,
    initialFearOfMovement: -1,
  },
  getters: {
    selfTestAnswers: (state) => {
      const fitness_activities = state.fitnessActivities
        .filter((a) => a.checked)
        .map((a) => a.value)
      let chronification_level = state.chronificationLevels.filter(
        (l) => l.checked,
      )
      chronification_level =
        (chronification_level.length && chronification_level[0].value) || null

      return import.meta.env.VITE_DISEASE === 'copd'
        ? {
            ...(state.gender != null ? { gender: state.gender } : {}),
            red_flag_approved: state.redFlagApproved ? 1 : null,
            fitness_activities:
              fitness_activities.length > 0 ? fitness_activities : null,
            ...(state.painIntensity > -1
              ? { pain_level: state.painIntensity }
              : {}),
            ...(state.initialFearOfMovement > -1
              ? { 'fear-of-movement': state.initialFearOfMovement }
              : {}),
          }
        : {
            gender: state.gender,
            red_flag_approved: state.redFlagApproved ? 1 : null,
            fitness_activities:
              fitness_activities.length > 0 ? fitness_activities : null,
            'pain-location': [state.worstPainLocation],
            worst_pain_location: state.worstPainLocation,
            chronification_level,
            ...(state.initialFearOfMovement > -1
              ? { 'fear-of-movement': state.initialFearOfMovement }
              : {}),
            ...(state.painIntensity > -1
              ? { pain_level: state.painIntensity }
              : {}),
          }
    },
    gender: (state) => state.gender,
    selectedFitnessActivities: (state) =>
      state.fitnessActivities.filter((f) => f.checked),
    fitnessActivities: (state) => state.fitnessActivities,
    worstPainLocation: (state) => state.worstPainLocation,
    chronificationLevels: (state) => state.chronificationLevels,
    selectedChronificationLevels: (state) =>
      state.chronificationLevels.filter((c) => c.checked),
    painIntensity: (state) => state.painIntensity,
    initialFearOfMovement: (state) => state.initialFearOfMovement,
  },
  mutations: {
    setGender(state, val) {
      state.gender = val
    },
    setFitnessActivities(state, val) {
      state.fitnessActivities = val
    },
    setChronificationLevels(state, val) {
      state.chronificationLevels = val
    },
    updateWorstPainLocation(state, val) {
      state.worstPainLocation = val
    },
    updateRedFlagApproved(state, val) {
      state.redFlagApproved = val
    },
    updateChronificationLevels(state, item) {
      state.chronificationLevels.forEach((c) => {
        c.checked = c.value === item.value
      })
    },
    updateFitnessActivities(state, item) {
      state.fitnessActivities.forEach((f) => {
        f.checked = f.value === item.value ? item.checked : f.checked
      })
    },
    updatePainIntensity(state, val) {
      state.painIntensity = val
    },
    updateInitialFearOfMovement(state, val) {
      state.initialFearOfMovement = val
    },
  },
}
