<template>
  <div class="welcome-to-kaia self-test">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b__red_flags_eu__sub_title')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b__red_flags_eu__notes')"
        />
      </div>
      <ConfirmationBox
        :label-title="$t('b2b__red_flags_eu__checkbox_label__medically_clear')"
        :cta-title="$t('b2b_sign_up_submit_button_text')"
        :checked="confirmed"
        :cta-enabled="confirmed"
        @change="handleConfirmationBoxChange"
        @submit="next"
      />
    </div>
  </div>
</template>

<script>
import { createHelpers } from '@shared/vuex-utils'
import ConfirmationBox from '@shared/components/ConfirmationBox.vue'

const { mapFields } = createHelpers({
  getterType: 'b2b/getField',
  mutationType: 'b2b/updateField',
})

export default {
  name: 'QuestionnaireSelfTestPrecautionsCOPD',
  components: {
    ConfirmationBox,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapFields({
      confirmed: 'formData.confirmed',
    }),
  },
  methods: {
    handleConfirmationBoxChange({ checked }) {
      this.confirmed = checked
    },
    next() {
      this.$store.commit('assessment/updateRedFlagApproved', this.confirmed)

      this.handleNextEvent()
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';
</style>
