<template>
  <svg
    viewBox="0 0 138 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      :class="bodyParts"
      transform="translate(10 10)"
      @click="handleClickEvent"
    >
      <path
        d="M51.9277 382.302H43.9405C44.3288 384.224 44.6616 385.981 44.3288 387.957C43.8851 390.648 42.9421 393.338 41.5 395.699C40.4461 397.456 38.8931 398.499 37.0627 399.377C36.3971 399.707 35.7315 400.201 35.5096 400.915C35.3432 401.409 35.4541 402.013 35.7869 402.397C36.4525 403.221 37.5619 403.001 38.5048 403.001C39.5032 403.001 50.2083 403.001 50.9293 403.001C51.9832 403.001 52.8152 402.068 52.7597 400.915C52.3715 397.73 51.7613 394.601 51.484 391.416C51.3176 388.397 51.6504 385.377 51.9277 382.302Z"
        fill="#FCC3B4"
      />
      <path
        d="M66.7923 382.302H74.7795C74.3913 384.224 74.0585 385.981 74.3913 387.957C74.835 390.648 75.7779 393.338 77.22 395.699C78.2739 397.456 79.827 398.499 81.6574 399.377C82.323 399.707 82.9886 400.201 83.2104 400.915C83.3768 401.409 83.2659 402.013 82.9331 402.397C82.2675 403.221 81.1582 403.001 80.2152 403.001C79.2168 403.001 68.5118 403.001 67.7907 403.001C66.7369 403.001 65.9049 402.068 65.9603 400.915C66.3486 397.73 66.9587 394.601 67.2361 391.416C67.347 388.397 67.0142 385.377 66.7923 382.302Z"
        fill="#FCC3B4"
      />
      <path
        d="M24.1384 189.648C25.636 180.534 27.9102 171.475 31.571 163.02C32.0147 161.977 33.6787 157.09 35.1763 155.388C35.5091 155.004 36.0083 154.784 36.5075 154.784C38.8926 154.784 48.7656 154.894 59.3043 154.949C69.843 154.894 79.7715 154.784 82.1011 154.784C82.6003 154.784 83.0995 155.004 83.4323 155.388C84.8744 157.09 86.5384 161.977 87.0377 163.02C90.6984 171.475 92.9726 180.534 94.4702 189.648C98.7411 215.452 98.963 237.523 92.1406 262.779C92.0851 262.998 92.0297 263.163 91.9742 263.383C90.9758 267.116 90.0328 270.245 89.2563 272.771C87.315 278.81 87.315 285.069 87.2595 291.438C87.0931 306.207 86.3166 330.529 83.2659 343.486C80.659 354.357 76.499 373.957 74.724 382.303H66.7368C65.5166 365.887 62.7433 321.141 67.6798 293.744C68.5672 288.803 67.7352 283.807 67.4025 278.755C66.8478 270.795 64.7955 257.947 63.2425 243.288C61.9667 231.539 60.8019 217.813 60.0808 204.581L59.3598 204.472L58.6387 204.581C57.9176 217.813 56.7528 231.539 55.4771 243.288C53.924 257.947 51.8718 270.795 51.3171 278.755C50.9843 283.752 50.1523 288.803 51.0398 293.744C55.9763 321.141 53.1475 365.941 51.9827 382.303H43.8846C42.1096 373.902 37.9496 354.357 35.3427 343.486C32.2366 330.529 31.5155 306.207 31.3491 291.438C31.2936 285.069 31.2936 278.81 29.3523 272.771C28.5203 270.245 27.5774 267.116 26.6344 263.383C26.579 263.163 26.5235 262.998 26.468 262.779C19.7011 237.523 19.8675 215.452 24.1384 189.648Z"
        fill="#777A80"
      />
      <path
        d="M31.0168 164.392L86.9272 162.8C86.9272 162.855 86.9827 162.91 86.9827 162.965C88.5912 166.534 89.9224 170.267 91.0318 174.055C85.3742 175.593 55.3667 182.95 27.6888 173.451C28.6872 170.377 29.7411 167.357 31.0168 164.392Z"
        fill="#515559"
      />
      <path
        d="M57.4188 204.416C57.3079 204.416 57.2525 204.416 57.1415 204.361C57.0861 204.306 49.2098 200.024 36.6743 198.981C36.3415 198.981 36.1197 198.706 36.1751 198.377C36.1751 198.047 36.4525 197.828 36.7853 197.883C49.598 198.981 57.3634 203.263 57.6962 203.428C57.9735 203.593 58.0844 203.922 57.918 204.196C57.8071 204.306 57.6407 204.416 57.4188 204.416Z"
        fill="#515559"
      />
      <path
        d="M57.4194 204.417C57.3639 204.417 57.3085 204.417 57.3085 204.417C57.253 204.417 53.2039 203.429 46.7143 203.483C46.437 203.483 46.1597 203.264 46.1597 202.934C46.1597 202.605 46.3815 202.385 46.7143 202.385C53.3149 202.33 57.4194 203.264 57.5858 203.319C57.8631 203.374 58.085 203.703 57.9741 203.978C57.9186 204.252 57.6967 204.417 57.4194 204.417Z"
        fill="#515559"
      />
      <path
        d="M61.6902 204.416C61.4683 204.416 61.3019 204.306 61.191 204.142C61.0246 203.867 61.1355 203.538 61.4128 203.373C61.7456 203.208 69.511 198.926 82.3238 197.828C82.6011 197.773 82.8784 198.047 82.9339 198.322C82.9339 198.597 82.712 198.871 82.4347 198.926C69.8438 200.024 62.023 204.306 61.9675 204.306C61.8566 204.361 61.8011 204.416 61.6902 204.416Z"
        fill="#515559"
      />
      <path
        d="M61.6899 204.417C61.468 204.417 61.1907 204.252 61.1352 203.978C61.0797 203.703 61.2461 203.374 61.5235 203.319C61.6899 203.264 65.7389 202.33 72.3949 202.385C72.7277 202.385 72.9496 202.66 72.9496 202.934C72.9496 203.264 72.6723 203.483 72.3949 203.483C65.9053 203.429 61.8563 204.362 61.8008 204.417C61.8008 204.417 61.7453 204.417 61.6899 204.417Z"
        fill="#515559"
      />
      <path
        d="M106.617 134.36L105.175 101.144L88.4799 104.053C92.3625 119.152 99.074 143.419 99.2404 149.239C99.3513 155.168 103.179 171.419 104.843 180.149L101.071 187.506C100.405 189.043 100.017 190.8 99.906 192.557L99.5177 201.232C99.5177 201.781 99.7951 202.275 100.239 202.385C100.683 202.495 101.182 202.165 101.293 201.616L102.901 196.016L103.345 197.773L100.183 210.016C100.017 210.73 100.294 211.499 100.849 211.773L101.459 212.103L101.348 212.597C101.182 213.31 101.515 214.079 102.125 214.299C102.679 214.518 103.289 214.244 103.567 213.64L109.89 199.42C110.278 198.487 110.5 197.498 110.556 196.455L110.999 186.243H111.055C111.055 186.243 111.055 186.133 111.055 185.969L110.833 179.435C110.777 175.427 110.777 173.286 110.999 165.27C111.388 147.592 106.617 134.36 106.617 134.36Z"
        fill="#FCC3B4"
      />
      <path
        d="M101.848 211.829C101.793 211.829 101.737 211.829 101.682 211.829C101.404 211.719 101.238 211.444 101.293 211.17L104.788 199.42C104.455 198.322 102.625 191.844 102.791 188.55C102.791 188.22 103.068 188.001 103.346 188.056C103.678 188.056 103.9 188.33 103.845 188.605C103.678 191.954 105.842 199.201 105.842 199.256C105.897 199.366 105.897 199.475 105.842 199.585L102.292 211.499C102.292 211.664 102.07 211.829 101.848 211.829Z"
        fill="#F29788"
      />
      <path
        d="M91.5859 115.858L105.564 110.642L105.342 104.768C102.291 105.646 96.2451 107.403 96.356 107.513C96.467 107.623 90.4766 111.74 90.4766 111.74L91.5859 115.858Z"
        fill="#F29788"
      />
      <path
        d="M104.232 139.137C104.066 139.137 103.788 139.082 103.511 138.917C102.901 138.478 102.402 137.325 102.18 136.007C101.958 134.36 102.235 132.493 103.4 132.274C103.622 132.219 103.955 132.219 104.288 132.493C104.898 132.933 105.397 134.086 105.619 135.403C105.841 137.05 105.563 138.917 104.399 139.137C104.343 139.137 104.288 139.137 104.232 139.137ZM103.567 133.317C103.456 133.427 103.067 134.305 103.289 135.843C103.511 137.27 104.01 138.039 104.232 138.039C104.343 137.929 104.731 137.05 104.51 135.513C104.343 134.086 103.788 133.372 103.567 133.317Z"
        fill="#F29788"
      />
      <path
        d="M106.784 180.753C106.784 180.753 106.729 180.753 106.784 180.753L105.12 180.644C104.787 180.644 104.565 180.369 104.621 180.04C104.621 179.765 104.898 179.491 105.231 179.546L106.84 179.655C107.172 179.655 107.394 179.93 107.339 180.259C107.283 180.534 107.061 180.753 106.784 180.753Z"
        fill="#F29788"
      />
      <path
        d="M82.379 288.198H71.2302C70.8974 288.198 70.6755 287.979 70.6755 287.649C70.6755 287.32 70.8974 287.1 71.2302 287.1H82.379C82.7118 287.1 82.9337 287.32 82.9337 287.649C82.9337 287.979 82.7118 288.198 82.379 288.198Z"
        fill="#515559"
      />
      <path
        d="M45.2158 288.198H34.067C33.7342 288.198 33.5123 287.979 33.5123 287.649C33.5123 287.32 33.7342 287.1 34.067 287.1H45.2158C45.5486 287.1 45.7705 287.32 45.7705 287.649C45.7705 287.979 45.5486 288.198 45.2158 288.198Z"
        fill="#515559"
      />
      <path
        d="M11.4928 134.36L12.9349 101.144L29.6304 104.053C25.7477 119.152 19.0363 143.419 18.8699 149.239C18.7589 155.168 14.9317 171.419 13.2677 180.149L17.0395 187.506C17.7051 189.043 18.0933 190.8 18.2043 192.557L18.5925 201.232C18.5925 201.781 18.3152 202.275 17.8715 202.385C17.4277 202.495 16.9285 202.165 16.8176 201.616L15.2091 195.906L14.7653 197.663L17.9269 209.906C18.0933 210.62 17.816 211.389 17.2613 211.663L16.6512 211.993L16.7621 212.487C16.9285 213.201 16.5957 213.969 15.9856 214.189C15.4309 214.409 14.8208 214.134 14.5435 213.53L8.1648 199.365C7.77653 198.432 7.55467 197.443 7.4992 196.4L7.05547 186.188H7C7 186.188 7 186.079 7 185.914L7.22187 179.38C7.27733 175.372 7.27733 173.231 7.05547 165.215C6.72267 147.592 11.4928 134.36 11.4928 134.36Z"
        fill="#FCC3B4"
      />
      <path
        d="M16.2626 211.828C16.0407 211.828 15.8189 211.664 15.7079 211.444L12.1581 199.53C12.1026 199.42 12.1026 199.311 12.1581 199.201C12.1581 199.146 14.3213 191.899 14.1549 188.55C14.1549 188.22 14.3767 188 14.6541 188C14.9869 188 15.2087 188.22 15.2087 188.495C15.3751 191.789 13.5447 198.267 13.2119 199.365L16.7063 211.115C16.8173 211.389 16.6509 211.719 16.3181 211.773C16.3735 211.828 16.3181 211.828 16.2626 211.828Z"
        fill="#F29788"
      />
      <path
        d="M26.5243 115.858L12.5467 110.642L12.7686 104.768C15.8192 105.646 21.8651 107.403 21.7542 107.513C21.6432 107.623 27.6336 111.74 27.6336 111.74L26.5243 115.858Z"
        fill="#F29788"
      />
      <path
        d="M13.8775 139.137C13.822 139.137 13.7666 139.137 13.7111 139.137C12.5463 138.917 12.269 137.05 12.4908 135.403C12.6572 134.031 13.1564 132.933 13.822 132.493C14.1548 132.219 14.4876 132.219 14.7095 132.274C15.8743 132.493 16.1516 134.36 15.9298 136.007C15.7634 137.38 15.2642 138.478 14.5986 138.917C14.3212 139.082 14.0994 139.137 13.8775 139.137ZM14.4876 133.317C14.3212 133.372 13.822 134.086 13.6002 135.513C13.3783 136.995 13.7666 137.929 13.933 138.039C14.0994 137.984 14.5986 137.27 14.8204 135.843C15.0423 134.305 14.654 133.427 14.4876 133.317Z"
        fill="#F29788"
      />
      <path
        d="M11.3262 180.753C11.0489 180.753 10.7715 180.534 10.7715 180.259C10.7715 179.93 10.9934 179.71 11.2707 179.655L12.8793 179.545C13.2121 179.545 13.4339 179.765 13.4894 180.04C13.4894 180.369 13.2675 180.589 12.9902 180.643L11.3262 180.753C11.3817 180.753 11.3262 180.753 11.3262 180.753Z"
        fill="#F29788"
      />
      <path
        d="M40.0025 63.8646L52.7599 58.8843L58.9158 59.4251L58.9163 59.088L59.0551 59.2563L59.194 59.088L59.1945 59.4251L65.3504 58.8843L78.1077 63.8646L89.0347 67.4882C89.312 67.598 89.6032 67.7078 89.8944 67.8176C90.1856 67.9274 90.4768 68.0372 90.7541 68.147L91.9744 68.5863C97.743 70.7275 101.903 75.7237 102.901 81.763L107.172 107.293L89.312 113.717L88.2581 109.215C85.3739 117.121 79.5499 139.741 91.6416 170.486C81.3242 173.62 71.9738 174.999 59.3604 175.042V175.043C59.2584 175.043 59.1567 175.043 59.0551 175.043C58.9536 175.043 58.8518 175.043 58.7499 175.043V175.042C46.1364 174.999 36.7861 173.62 26.4687 170.486C38.5604 139.796 32.7364 117.175 29.8521 109.215L28.7983 113.717L10.938 107.293L15.2089 81.763C16.2073 75.7786 20.3673 70.7275 26.1359 68.5863L27.3561 68.147C27.9108 67.8725 28.4655 67.6529 29.0756 67.4882L40.0025 63.8646Z"
        fill="#EDEFF2"
      />
      <path
        d="M29.8519 109.05C29.7965 109.05 29.7965 109.05 29.741 109.05C29.4637 108.995 29.2418 108.721 29.2973 108.391L31.2386 99.0576C31.2941 98.783 31.5714 98.5634 31.9042 98.6183C32.1815 98.6732 32.4034 98.9477 32.3479 99.2772L30.3511 108.611C30.3511 108.83 30.0738 109.05 29.8519 109.05Z"
        fill="#C4C7CC"
      />
      <path
        d="M29.852 109.05C29.5747 109.05 29.3528 108.83 29.2974 108.556L27.6334 96.6416C27.5779 96.3671 27.7998 96.0377 28.1326 96.0377C28.4099 95.9828 28.7427 96.2024 28.7427 96.5318L30.4067 108.446C30.4622 108.72 30.2403 109.05 29.9075 109.05H29.852Z"
        fill="#C4C7CC"
      />
      <path
        d="M88.2582 109.05C87.9809 109.05 87.759 108.885 87.7035 108.611L85.7622 99.2771C85.7068 99.0026 85.8732 98.6732 86.206 98.6183C86.4833 98.5634 86.8161 98.7281 86.8715 99.0575L88.8129 108.391C88.8683 108.666 88.7019 108.995 88.3692 109.05C88.3692 108.995 88.3137 109.05 88.2582 109.05Z"
        fill="#C4C7CC"
      />
      <path
        d="M88.2588 109.05H88.2033C87.926 108.995 87.7041 108.72 87.7041 108.446L89.3681 96.5317C89.4236 96.2572 89.7009 96.0376 89.9782 96.0376C90.2556 96.0925 90.4774 96.367 90.4774 96.6415L88.8134 108.555C88.8134 108.83 88.5361 109.05 88.2588 109.05Z"
        fill="#C4C7CC"
      />
      <path
        d="M63.3543 58.4294L78.9404 63.8648C74.0039 68.1473 66.8487 70.7826 58.8615 70.7826C51.0407 70.7826 44.0519 68.2022 39.1154 64.1393L55.5335 58.3745H59.4716H63.3543V58.4294Z"
        fill="#FCC3B4"
      />
      <path
        d="M46.547 41.2445L47.379 39.5425C46.6579 38.6091 45.9368 37.6209 45.715 36.6326C45.4931 36.413 45.2712 36.1934 45.1048 35.9738C37.7833 27.7932 38.6707 15.3852 46.1033 7.64382C49.043 4.56925 52.9811 2.31823 57.252 1.60449C67.5688 -0.0975066 78.3848 7.3144 81.1582 17.142C81.2136 17.3068 81.2691 17.5264 81.3246 17.746C81.6019 18.7891 81.8238 19.9421 81.8792 21.0402C82.1011 23.1814 82.5448 27.0795 82.8222 31.4717C83.3214 34.2169 84.9299 36.6326 85.7064 38.0052C86.0392 38.5542 85.651 39.268 84.9854 39.268L83.155 39.3229C83.155 39.8719 83.155 40.4209 83.0995 40.97C83.044 46.5701 82.4339 51.4016 80.659 52.7741C78.995 54.0918 77.22 54.0918 75.4451 53.7624L68.7891 52.0055C68.7891 52.0055 65.7939 59.9115 70.1758 65.2371L50.4851 67.0489C50.4297 67.1038 53.203 50.4133 46.547 41.2445Z"
        fill="#FCC3B4"
      />
      <path
        d="M76.3894 32.4048C78.6636 32.5695 80.383 34.546 80.1612 36.7971C79.9948 39.0481 77.998 40.7501 75.7238 40.5305C73.4497 40.3658 71.7302 38.3893 71.9521 36.1382C72.1185 33.9421 74.1153 32.2401 76.3894 32.4048Z"
        fill="#FCB19E"
      />
      <path
        d="M82.7674 31.4717C82.7119 30.7031 82.6565 29.9894 82.601 29.2756C82.4901 29.2756 82.4346 29.2756 82.3237 29.2756C81.4917 29.3305 80.8261 29.9894 80.8815 30.7031C80.937 31.4168 81.7135 31.911 82.5455 31.8561C82.6565 31.8561 82.7674 31.8012 82.8229 31.8012C82.8229 31.6914 82.7674 31.5816 82.7674 31.4717Z"
        fill="#333333"
      />
      <path
        d="M82.1575 26.5304C82.2129 26.5304 82.3239 26.5304 82.3793 26.4755C82.3239 26.1461 82.3239 25.7618 82.2684 25.4324C82.2129 25.4324 82.2129 25.4324 82.1575 25.4324C81.9911 25.4324 81.8247 25.4324 81.6583 25.4324C80.6044 25.5422 79.606 25.9265 78.6631 26.5304C78.4412 26.6951 78.3857 27.0795 78.5521 27.2991C78.6631 27.4638 78.8295 27.5187 78.9959 27.5187C79.1068 27.5187 79.2177 27.4638 79.3287 27.4089C80.0497 26.8599 80.8817 26.5853 81.7692 26.4755C81.8801 26.5853 82.0465 26.5304 82.1575 26.5304Z"
        fill="#333333"
      />
      <path
        d="M69.6767 11.8715C69.7322 11.8166 71.7845 29.715 62.2442 33.9974C52.7039 38.2799 56.5311 8.90676 56.5311 8.90676C56.5311 8.90676 57.9733 4.78903 59.1935 1.38504C68.7338 0.836012 78.2186 7.7538 80.9365 16.7579C77.6639 15.3853 69.6767 11.9264 69.6767 11.8715Z"
        fill="#212226"
      />
      <path
        d="M49.2646 35.9739L46.6022 41.2446C46.6022 41.2446 29.5185 22.3579 47.3233 6.49097"
        fill="#212226"
      />
      <path
        d="M81.4358 42.1779C81.2139 41.9583 80.7702 41.9583 80.4929 42.123C80.2155 42.3426 80.1601 42.6721 80.4374 42.8917C80.5483 43.0015 81.2139 43.5505 82.9888 43.825C82.9888 43.4407 83.0443 43.1113 83.0443 42.727C81.8795 42.5622 81.4358 42.2328 81.4358 42.1779Z"
        fill="#F29788"
      />
      <path
        d="M82.8222 21.5344C81.9347 20.8756 81.0473 20.1618 80.2153 19.4481C78.6622 17.9657 77.1646 16.4833 76.1662 14.7264L83.7097 18.2402C83.7097 18.2402 79.6606 -2.67782 57.7513 1.49481C57.5849 1.49481 57.363 1.54972 57.1966 1.54972C52.9257 2.26346 49.043 4.45958 46.0478 7.58905C45.715 7.91847 45.4377 8.30279 45.1049 8.63221C44.2174 7.80866 43.1635 7.14983 41.9987 6.87531C37.0622 5.61254 31.571 10.2793 29.7961 17.1971C28.0211 24.1149 30.6281 30.7581 35.5646 32.0209C36.1193 32.1307 36.6739 32.2405 37.1731 32.2405C38.227 32.2405 39.2809 31.966 40.3347 31.5268C41.4995 35.6445 43.9955 42.0682 48.7102 45.8016C59.7481 46.7898 62.6323 41.0799 62.6323 41.0799C64.9065 38.2798 66.515 34.1621 67.6798 29.7699L68.6227 32.8993C74.3913 29.8797 72.8382 20.4364 71.4515 15.2206C71.9507 15.8245 72.5054 16.4284 73.0601 16.9226C74.835 18.6795 76.9982 19.8873 79.0505 20.9305C80.1598 21.3697 81.2137 21.8638 82.2675 22.2482L85.5401 23.2913L82.8222 21.5344ZM35.8974 30.9777C32.5694 30.1542 30.5171 26.4208 30.4062 21.9187C31.0163 25.5972 33.1795 28.4522 36.3411 29.2757C37.5059 29.5503 38.6707 29.5503 39.8355 29.2208C39.891 29.6052 40.0019 29.9895 40.1129 30.4836C38.7817 31.1425 37.3395 31.3621 35.8974 30.9777ZM40.723 29.0012C40.8339 28.9463 40.9449 28.8914 41.0558 28.8365C41.1113 29.111 41.2222 29.3306 41.2777 29.6052L40.723 29.0012ZM68.4563 11.3774C68.4563 10.8283 68.4563 10.2244 68.4563 9.62046L68.7891 9.89498C68.7891 9.94988 68.7337 10.0048 68.7337 10.0597C68.7891 10.2244 68.8446 10.3342 68.8446 10.4989C68.9001 10.6087 68.9001 10.7185 68.9555 10.7734L68.4563 11.3774Z"
        fill="#333333"
      />
      <path
        d="M60.1373 34.8754C59.028 31.9656 60.4701 28.7812 63.4099 27.6831C66.2941 26.5851 69.5667 28.0674 70.676 30.9224C71.7853 33.8323 70.3432 37.0166 67.4035 38.1147C64.5192 39.2676 61.2467 37.7853 60.1373 34.8754Z"
        fill="#FCC3B4"
      />
      <path
        d="M62.3002 34.9305C62.0783 34.9305 61.8564 34.8207 61.801 34.6011C61.4127 33.558 61.4127 32.4599 61.9119 31.4167C62.3556 30.4285 63.1876 29.6598 64.2415 29.2755C64.3524 29.2206 64.4634 29.2206 64.5743 29.1657C64.8516 29.0559 65.1844 29.2206 65.2399 29.55C65.3508 29.8245 65.1844 30.154 64.8516 30.2089C64.7962 30.2089 64.6852 30.2638 64.6298 30.2638C63.8532 30.5383 63.2986 31.0873 62.9658 31.856C62.633 32.5697 62.633 33.4481 62.9103 34.1619C63.0212 34.4364 62.8548 34.7658 62.5775 34.8756C62.4111 34.9305 62.3556 34.9305 62.3002 34.9305Z"
        fill="#F29788"
      />
      <path
        d="M77.9968 32.076C77.8304 32.076 77.6085 31.9661 77.4976 31.8014C77.3312 31.5269 77.4421 31.1975 77.7194 31.0328L78.2741 30.7034L77.1648 30.374C76.8874 30.2642 76.721 29.9896 76.7765 29.6602C76.8874 29.3857 77.1648 29.221 77.4976 29.2759L79.7717 29.9896C79.9936 30.0445 80.16 30.2093 80.16 30.4289C80.16 30.6485 80.1045 30.8681 79.8826 30.9779L78.2186 31.9112C78.1632 32.076 78.1077 32.076 77.9968 32.076Z"
        fill="#F29788"
      />
      <path
        d="M70.0098 48.7113C69.7325 48.6564 69.3997 48.8211 69.3442 49.1506L68.7341 51.9506C68.7341 52.0055 68.7341 52.0055 68.7341 52.0604L69.7879 52.3349C69.7879 52.28 69.8434 52.28 69.8434 52.2251L70.4535 49.4251C70.509 49.0957 70.3426 48.8211 70.0098 48.7113Z"
        fill="#F29788"
      />
      <path
        d="M58.8053 74.0769C50.319 74.0769 42.4427 71.3867 36.5632 66.5003C36.3414 66.2807 36.2859 65.9513 36.5078 65.7316C36.7296 65.512 37.0624 65.4571 37.2843 65.6767C42.9419 70.3984 50.5963 72.9789 58.8053 72.9789C67.1253 72.9789 75.1125 70.1788 80.7147 65.3473C80.9365 65.1277 81.2693 65.1826 81.4912 65.4022C81.7131 65.6218 81.6576 65.9513 81.4357 66.1709C75.6672 71.222 67.4027 74.0769 58.8053 74.0769Z"
        fill="#C4C7CC"
      />
      <path
        d="M48.8217 45.8562C48.8217 45.8562 53.8137 57.9898 43.6078 62.6016L49.2654 65.8409L59.3603 61.6134"
        fill="#FCC3B4"
      />
      <path
        d="M68.7349 52.0605C68.7349 52.0605 65.5178 59.5823 74.3925 62.2725L65.9061 65.0177L58.5845 61.8882"
        fill="#FCC3B4"
      />
      <g data-type="ankle-or-foot">
        <circle
          cx="71.6472"
          cy="393"
          r="7"
          transform-origin="71.6472px 393px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="71.6472"
          cy="393"
          r="7"
          transform-origin="71.6472px 393px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="71.6472"
          cy="393"
          r="7"
        />

        <circle
          cx="47"
          cy="393"
          r="7"
          transform-origin="47px 393px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="47"
          cy="393"
          r="7"
          transform-origin="47px 393px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="47"
          cy="393"
          r="7"
        />
      </g>
      <g data-type="knee">
        <circle
          cx="41.5947"
          cy="287"
          r="7.85863"
          transform-origin="41.5947px 287px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="41.5947"
          cy="287"
          r="7.85863"
          transform-origin="41.5947px 287px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="41.5947"
          cy="287"
          r="7.85863"
        />

        <circle
          cx="78.4054"
          cy="287"
          r="7.85863"
          transform-origin="78.4054px 287px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="78.4054"
          cy="287"
          r="7.85863"
          transform-origin="78.4054px 287px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="78.4054"
          cy="287"
          r="7.85863"
        />
      </g>
      <g data-type="hip">
        <circle
          cx="39.3235"
          cy="191.324"
          r="8.78959"
          transform-origin="39.3235px 191.324px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="39.3235"
          cy="191.324"
          r="8.78959"
          transform-origin="39.3235px 191.324px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="39.3235"
          cy="191.324"
          r="8.78959"
        />

        <circle
          cx="78.9707"
          cy="191.324"
          r="8.78959"
          transform-origin="78.9707px 191.324px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="78.9707"
          cy="191.324"
          r="8.78959"
          transform-origin="78.9707px 191.324px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="78.9707"
          cy="191.324"
          r="8.78959"
        />
      </g>
      <g data-type="hand-or-wrist">
        <circle
          cx="106.647"
          cy="184"
          r="7"
          transform-origin="106.647px 184px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="106.647"
          cy="184"
          r="7"
          transform-origin="106.647px 184px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="106.647"
          cy="184"
          r="7"
        />

        <circle
          cx="11"
          cy="184"
          r="7"
          transform-origin="11px 184px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="11"
          cy="184"
          r="7"
          transform-origin="11px 184px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="11"
          cy="184"
          r="7"
        />
      </g>
      <g data-type="elbow">
        <circle
          cx="102"
          cy="137"
          r="6"
          transform-origin="102px 137px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="102"
          cy="137"
          r="6"
          transform-origin="102px 137px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="102"
          cy="137"
          r="6"
        />

        <circle
          cx="16"
          cy="137"
          r="6"
          transform-origin="16px 137px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="16"
          cy="137"
          r="6"
          transform-origin="16px 137px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="16"
          cy="137"
          r="6"
        />
      </g>
      <g data-type="lower-back">
        <circle
          cx="59"
          cy="162"
          r="12.4"
          transform-origin="59px 162px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="59"
          cy="162"
          r="12.4"
          transform-origin="59px 162px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="59"
          cy="162"
          r="12.4"
        />
      </g>
      <g data-type="upper-back">
        <circle
          cx="59"
          cy="101.5"
          r="12.2"
          transform-origin="59px 101.5px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="59"
          cy="101.5"
          r="12.2"
          transform-origin="59px 101.5px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="59"
          cy="101.5"
          r="12.2"
        />
      </g>
      <g data-type="shoulder">
        <circle
          cx="27"
          cy="78"
          r="7"
          transform-origin="27px 78px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="27"
          cy="78"
          r="7"
          transform-origin="27px 78px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="27"
          cy="78"
          r="7"
        />

        <circle
          cx="91"
          cy="78"
          r="7"
          transform-origin="91px 78px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="91"
          cy="78"
          r="7"
          transform-origin="91px 78px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="91"
          cy="78"
          r="7"
        />
      </g>
      <g data-type="neck">
        <circle
          cx="60"
          cy="58"
          r="7"
          transform-origin="60px 58px"
          class="figure-pulsation-outer"
        />
        <circle
          cx="60"
          cy="58"
          r="7"
          transform-origin="60px 58px"
          class="figure-pulsation-inner"
        />
        <circle
          cx="60"
          cy="58"
          r="7"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PainFemaleFigure',
  props: {
    bodyParts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClickEvent(event) {
      let { target } = event
      let dataType = target.getAttribute('data-type')

      while (dataType == null) {
        target = target.parentNode
        dataType = target.getAttribute('data-type')
        if (target.nodeName.toLowerCase() === 'svg') {
          break
        }
      }
    },
  },
}
</script>
<style lang="scss">
[data-type='ankle-or-foot'],
[data-type='knee'],
[data-type='hip'],
[data-type='hand-or-wrist'],
[data-type='elbow'],
[data-type='lower-back'],
[data-type='upper-back'],
[data-type='shoulder'],
[data-type='neck'] {
  fill: transparent;
  cursor: pointer;
}

.non-interactive [data-type] {
  cursor: default;
}

.transparent [data-type] {
  opacity: 0.5;
}

@keyframes figure-pulsation-inner {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    opacity: 0.25;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes figure-pulsation-outer {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    opacity: 0.15;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.ankle-or-foot [data-type='ankle-or-foot'],
.knee [data-type='knee'],
.hip [data-type='hip'],
.hand-or-wrist [data-type='hand-or-wrist'],
.elbow [data-type='elbow'],
.lower-back [data-type='lower-back'],
.upper-back [data-type='upper-back'],
.shoulder [data-type='shoulder'],
.neck [data-type='neck'] {
  fill: #ff375b;
}

.figure-pulsation-inner,
.figure-pulsation-outer {
  opacity: 0;
  transform: scale(1);
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.ankle--worst [data-type='ankle-or-foot'],
.foot--worst [data-type='ankle-or-foot'],
.knee--worst [data-type='knee'],
.hip--worst [data-type='hip'],
.hand--worst [data-type='hand-or-wrist'],
.wrist--worst [data-type='hand-or-wrist'],
.elbow--worst [data-type='elbow'],
.lower-back--worst [data-type='lower-back'],
.upper-back--worst [data-type='upper-back'],
.neck--worst [data-type='neck'],
.shoulder--worst [data-type='shoulder'] {
  opacity: 1;
  fill: #ff375b;

  .figure-pulsation-inner {
    animation-name: figure-pulsation-inner;
  }

  .figure-pulsation-outer {
    animation-name: figure-pulsation-outer;
  }
}
</style>
