<template>
  <div class="cat-quiz-content">
    <slot name="header" />

    <QuizContentContainer class="quiz-content-container">
      <div class="form-container">
        <slot />
      </div>

      <BaseButton
        :loading="inProgress"
        data-qa="submit"
        @click="clickBtnAction"
      >
        {{ $t('b2b_quiz_cat_next') }}
      </BaseButton>
    </QuizContentContainer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import QuizContentContainer from '@shared/components/Quiz/QuizContentContainer.vue'
import BaseButton from '@shared/components/BaseButton.vue'

const emit = defineEmits(['nextBtnClick'])

const store = useStore()
const inProgress = computed(() => store.getters['assessment/inProgress'])

const clickBtnAction = (event) => {
  emit('nextBtnClick', event)
}
</script>

<style lang="scss" scoped>
.quiz-content-container {
  display: flex;
  padding: 32px;
}
</style>
