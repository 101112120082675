<template>
  <div class="quiz-content__inner about-de self-test">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header header"
          v-html="$t('b2b_self_test_gender')"
        />
        <div
          class="self-test__description description"
          v-html="$t('b2b_self_test_about_description')"
        />
      </div>
      <div class="gender-groups">
        <button
          role="button"
          type="button"
          :class="['item', 'female', { 'female--active': gender === 'f' }]"
          data-qa="gender-female"
          :aria-label="$t('b2b_self_test_gender_female')"
          @click="gender = 'f'"
          @touchstart.prevent="handleTouchStartEvent"
          v-html="$t('b2b_self_test_gender_female')"
        />
        <button
          role="button"
          type="button"
          :class="['item', 'male', { 'male--active': gender === 'm' }]"
          data-qa="gender-male"
          :aria-label="$t('b2b_self_test_gender_male')"
          @click="gender = 'm'"
          @touchstart.prevent="handleTouchStartEvent"
          v-html="$t('b2b_self_test_gender_male')"
        />
        <button
          role="button"
          type="button"
          :class="['item', 'other', { 'other--active': gender === 'u' }]"
          data-qa="gender-other"
          :aria-label="$t('b2b_self_test_gender_other')"
          @click="gender = 'u'"
          @touchstart.prevent="handleTouchStartEvent"
          v-html="$t('b2b_self_test_gender_other')"
        />
      </div>
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-qa="submit"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@shared/components/BaseButton.vue'

export default {
  name: 'QuestionnaireSelfTestAboutDE',
  components: {
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    isValid() {
      return this.gender
    },
    gender: {
      get() {
        return this.$store.getters['assessment/gender']
      },
      set(gender) {
        this.$store.commit('assessment/setGender', gender)
        this.interactiveButtonState = 'ACTIVE'
      },
    },
  },
  created() {
    this.interactiveButtonState = this.gender ? 'ACTIVE' : 'DISABLED'
  },
  methods: {
    next() {
      if (this.isValid) {
        this.handleNextEvent()
      }
    },
    handleTouchStartEvent(event) {
      const handleTouchEndEvent = (event2) => {
        event2.target.removeEventListener('touchend', handleTouchEndEvent)
        event2.target.click()
      }
      event.target.addEventListener('touchend', handleTouchEndEvent)
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';

.about-de {
  .header,
  .description {
    text-align: center;
  }

  .gender-groups {
    display: flex;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      color: $text-secondary;
      flex-basis: 30%;
      cursor: pointer;

      @include kaia-typography-p2($font-weight-normal);

      &:before {
        content: '';
        display: inline-block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 16px;
        background: url('@shared/assets/img/gender-groups.svg') center no-repeat;
      }
    }

    .female {
      &:before {
        background-color: $gray-base;
        background-position: -88px 9px;
        background-size: 800%;
      }

      &--active:before {
        background-color: $sky;
        background-position: 9px 9px;
      }
    }

    .male {
      &:before {
        background-color: $gray-base;
        background-position: -380px 20px;
        background-size: 800%;
      }

      &--active:before {
        background-color: $sunset-red;
        background-position: -240px 20px;
      }
    }

    .other {
      &:before {
        background-color: $gray-base;
        background-position: -679px 9px;
        background-size: 800%;
      }

      &--active:before {
        background-color: $green;
        background-position: -540px 9px;
      }
    }
  }

  .interactive-button__layout {
    margin: 32px auto 0;
  }
}
</style>
